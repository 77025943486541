import { render, staticRenderFns } from "./ChatBoxTracking.vue?vue&type=template&id=521a8b86&scoped=true&"
import script from "./ChatBoxTracking.vue?vue&type=script&lang=js&"
export * from "./ChatBoxTracking.vue?vue&type=script&lang=js&"
import style0 from "./ChatBoxTracking.vue?vue&type=style&index=0&id=521a8b86&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "521a8b86",
  null
  
)

export default component.exports