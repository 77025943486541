var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form"},[_c('div',[(_vm.isError)?_c('div',{staticClass:"alert"},[_c('font-awesome-icon',{attrs:{"icon":"exclamation-circle"}}),_vm._v(" ไม่สำเร็จ ")],1):_vm._e()]),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.sendForm.apply(null, arguments)}}},[_vm._l((_vm.field),function(item,name){return _c('div',{key:name},[_c('InputText',{attrs:{"value":item.value,"textFloat":_vm.cookieLang == 'th' ? item.label : item.labelEn,"placeholder":_vm.cookieLang == 'th' ? item.label : item.labelEn,"name":item.fieldName,"type":item.type,"isRequired":item.required,"isValidate":_vm.$v.form[name].$error,"v":_vm.$v.form[name]},on:{"onDataChange":function (val) {
            _vm.$v.form[name].$model = val;
            _vm.field[name].value = val;
          }}})],1)}),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.consent),expression:"form.consent"}],attrs:{"type":"checkbox","id":"checkbox-consent"},domProps:{"checked":Array.isArray(_vm.form.consent)?_vm._i(_vm.form.consent,null)>-1:(_vm.form.consent)},on:{"change":function($event){var $$a=_vm.form.consent,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, "consent", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, "consent", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, "consent", $$c)}}}}),_c('span',{staticClass:"textConsentStart"},[_vm._v("ข้าพเจ้าได้อ่าน เข้าใจ และยอมรับเงื่อนไขการใช้งานและ")]),_c('a',{staticClass:"link-policy",attrs:{"href":"https://th.kerryexpress.com/th/privacy-notice","target":"_blank"}},[_vm._v("ประกาศความเป็นส่วนตัว")]),_c('span',{staticClass:"textConsentEnd"},[_vm._v("แล้ว")])]),(_vm.isLoading)?_c('div',{staticClass:"container-loader"},[_vm._m(0)]):_vm._e(),(_vm.buttonTitle)?_c('div',{staticClass:"container-btn"},[_c('button',{staticClass:"btn-chat",style:([
          _vm.form.consent
            ? { backgroundColor: _vm.primaryColor }
            : { backgroundColor: '#666666' } ]),attrs:{"type":"submit","disabled":!_vm.form.consent}},[_vm._v(" "+_vm._s(_vm.buttonTitle)+" ")])]):_c('div',{staticClass:"container-btn-icon"},[_c('button',{staticClass:"btn-icon-chat",style:([
          _vm.form.consent
            ? { color: _vm.primaryColor, border: 0 }
            : { color: '#666666', border: 0 } ]),attrs:{"type":"submit","disabled":!_vm.form.consent}},[_c('font-awesome-icon',{attrs:{"icon":"arrow-circle-right","id":"iconChat"}})],1)])],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"loader",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])}]

export { render, staticRenderFns }